import { useState } from 'react';

import PersonClubTransferData from './PersonClubTransferData';

const UsePersonClubTransferData = () => {
  const [personClubTransferState, setPersonClubTransferState] = useState(PersonClubTransferData.INITIAL_STATE);

  const getPersonClubTransfer = (isRevisit) => {
    PersonClubTransferData.getPersonClubTransferData(personClubTransferState, setPersonClubTransferState, isRevisit);
  };

  const getHqPersonClubTransfer = (isRevisit) => {
    PersonClubTransferData.getHqPersonClubTransferData(personClubTransferState, setPersonClubTransferState, isRevisit);
  };

  const getPersonClubTransferWorkflow = (workflowId) => {
    return PersonClubTransferData.getPersonClubTransferWorkflowData(workflowId, personClubTransferState, setPersonClubTransferState);
  };

  const postPersonClubTransfer = (workflowId, workflowStepId, stepNotes, status, lastCompetitionDate) => {
    const transferVerdictObj = {
      workflowId,
      workflowStepId,
      stepNotes,
      status,
      lastCompetitionDate
    };

    PersonClubTransferData.postPersonClubTransferData(transferVerdictObj, personClubTransferState, setPersonClubTransferState);
  };

  const clearObjData = () => {
    setPersonClubTransferState({
      ...personClubTransferState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setPersonClubTransferState({
      ...personClubTransferState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setPersonClubTransferState({
      ...personClubTransferState,
      isSaved: false
    });
  };

  const setObjData = (objData) => {
    setPersonClubTransferState({
      ...personClubTransferState,
      isObjLoading: false,
      isObjLoaded: true,
      objData
    });
  };

  return {
    personClubTransferState,
    confirmSave,
    clearObjData,
    clearArrayData,
    setObjData,
    getPersonClubTransfer,
    getHqPersonClubTransfer,
    postPersonClubTransfer,
    getPersonClubTransferWorkflow
  };
};

export default UsePersonClubTransferData;