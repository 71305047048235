import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import UseMeetSanctionData from '../../../state/workflow/meetSanction/UseMeetSanctionData';

import Constants from '../../../../common/utils/Constants';

const useMeetSanction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetSanctionState, getMeetSanction } = UseMeetSanctionData();

  const onSanctionClicked = (e, meetSanc) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.MEET_SANCTION_REQUEST_APPROVALS, { state: { meetSanc, isRevisit: location.state?.isRevisit } });
  };

  useEffect(() => {
    if (meetSanctionState.isArrayLoaded !== true) {
      if (location.state && location.state?.isRevisit === true) {
        getMeetSanction(true);
      }
      else {
        getMeetSanction(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    location,
    Constants,
    meetSanctionState,
    onSanctionClicked
  };
};

export default useMeetSanction;