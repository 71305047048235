import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './NewClubRegistrationApprovalValidation';
import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import UseNewClubRegistrationData from '../../../state/workflow/newClubRegistration/UseNewClubRegistrationData';
import useNewClubRegistrationPaymentData from '../../../state/workflow/newClubRegistration/UseNewClubRegistrationPaymentData';

import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { navLinks } from '../../../UseNavLinks';

const INITIAL_VIEW_STATE = {
  reportParameters: { clubRegistrationId: '' },
  routeName: ''
};

const useNewClubRegistrationApproval = (gridPagePath) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const { putNewClubRegistrationCartItem } = useNewClubRegistrationPaymentData();
  const { newClubRegistrationState, getNewClubRegistrationWorkflow, putNewClubRegistrationWorkflow
  } = UseNewClubRegistrationData();
  const { formState, errorState, handleSubmit, updateFormState, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [clubRegObjState, setClubRegObjState] = useState({});
  const [trySubmitState, setTrySubmitState] = useState(false);

  const onApprovedClicked = () => {
    updateFormState('isRevisit', false);
    
    if (gridPagePath === navLinks.HQ_NEW_CLUB_REGISTRATION) {
      const wfsteparray = newClubRegistrationState.objData.workflowStep;

      //only allow HQ approval if we already have LSC approval
      const wfstep = wfsteparray.filter(x => x.stepStatus === "Approved" && x.workflowTypeStepId === 2);

      if (wfstep.length > 0) {
        const clubRegObj = { clubRegistrationId: clubRegObjState.clubRegistrationId, offeringInstanceId: clubRegObjState.offeringInstanceId };

        const putNewClubRegistrationCartItemPromise =
          putNewClubRegistrationCartItem(
            clubRegObjState.completedByPersonId,
            clubRegObjState.orgUnitId,
            clubRegObjState.clubCode,
            clubRegObj);

        if (putNewClubRegistrationCartItemPromise ?? false) {
          putNewClubRegistrationCartItemPromise.then((newState) => {
            if (newState ?? false) {
              //do nothing 
            }
          }).catch((e) => {
            //context error
          });
        }
      }
    }

    setTrySubmitState(true);
  };

  const onRequestClicked = () => {
    updateFormState('isRevisit', true);
    setTrySubmitState(true);
  };

  const onBackClicked = () => {
    navigate(gridPagePath, { state: { isRevisit: location.state?.isRevisit } });
  };

  const onDownloadApplication = () => {
    setViewState({
      ...viewState,
      reportParameters: { clubRegistrationId: clubRegObjState.clubRegistrationId },
      routeName: gridPagePath === navLinks.HQ_NEW_CLUB_REGISTRATION ? 'WORKFLOWS_HQ_NEW_CLUB_REGISTRATION_APPROVALS' : gridPagePath === navLinks.LSC_NEW_CLUB_REGISTRATION ? 'WORKFLOWS_LSC_NEW_CLUB_REGISTRATION_APPROVALS' : '',
    });
  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {

        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const listNames = [];
            stateObj.arrayData.forEach(x => {
              if (listNames.filter(a => a.id === x.listName).length === 0) {
                listNames.push({ id: x.listName, name: x.listDisplayName });
              }
            });

            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: stateObj.arrayData.length === 1,
              reportInfoId: stateObj.arrayData.length === 1 ? stateObj.arrayData[0].reportInfoId : -1,
              listNames,
              rawReportList: [...stateObj.arrayData],
              reportList: [...stateObj.arrayData]
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: false,
              reportInfoId: -1,
              listNames: [],
              rawReportList: [],
              reportList: []
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showGrid: false,
            showReport: false,
            reportInfoId: -1,
            listNames: [],
            rawReportList: [],
            reportList: []
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName]);

  useEffect(() => {
    if (location.state?.clubReg?.workflowId > 0) {
      setClubRegObjState(location.state.clubReg);
      getNewClubRegistrationWorkflow(location.state.clubReg.workflowId);
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trySubmitState === true) {
      handleSubmit();
      setTrySubmitState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trySubmitState]);

  useEffect(() => {
    if (newClubRegistrationState.isSaved === true) {
      navigate(gridPagePath, { state: { isRevisit: location.state?.isRevisit } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClubRegistrationState.isSaved]);

  function submitFormCallback() {
    const workflowObjCopy = JSON.parse(JSON.stringify(newClubRegistrationState.objData));
    if (formState.isRevisit !== true) {
      putNewClubRegistrationWorkflow(clubRegObjState.workflowId,
        createNewWorkflowObj(workflowObjCopy, clubRegObjState.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
          formState.stepNotes, 0));
    } else {
      putNewClubRegistrationWorkflow(clubRegObjState.workflowId,
        createNewWorkflowObj(workflowObjCopy, clubRegObjState.workflowStepId, Constants.WORKFLOW_STATUS_SEND_BACK,
          formState.stepNotes, 0));
    }
  };

  function getInitialFormState() {
    return {
      stepNotes: '',
      isRevisit: undefined
    };
  };

  return {
    location,
    Constants,
    isSaving: newClubRegistrationState.isSaving,
    isLoading: newClubRegistrationState.isObjLoading,
    newClubRegistrationState,
    clubRegObjState,
    formState,
    errorState,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onFormValueChanged: updateFormState,
    onApprovedClicked,
    onRequestClicked,
    onBackClicked,
    onDownloadApplication
  };
};

export default useNewClubRegistrationApproval;