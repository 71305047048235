import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './SafeSportFilterValidation';

import { navLinks } from '../../../UseNavLinks';

import useSafeSportData from '../../../state/workflow/safeSport/UseSafeSportData';

import useProgramLevelData from '../../../../common/state/programLevel/UseProgramLevelData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useSafeSport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { safeSportState, getSafeSportRequest } = useSafeSportData();
  const { clearProgramLevelObjData } = useProgramLevelData();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { formState, errorState, onValueTextPairChanged, handleSubmit, setFormState, setErrors
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [filteredArrayData, setFilteredArrayData] = useState([]);

  const onEdit = (e, programLevel) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.SAFE_SPORT_PROGRAM_LEVEL, { state: { programLevel, isRevisit: location.state?.isRevisit } });
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState(getInitialFormState);
    setErrors({});
    setFilteredArrayData([...safeSportState.arrayData]);
  };

  useEffect(() => {
    clearProgramLevelObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (safeSportState.isArrayLoaded !== true) {
      if (location.state && location.state?.isRevisit === true) {
        getSafeSportRequest(true);
      }
      else {
        getSafeSportRequest(false);
      }
    } else {
      if (formState.orgUnitId) {
        const newFilteredArray = safeSportState.arrayData.filter((club) => club.clubOrgUnitId === formState.orgUnitId) || [];

        setFilteredArrayData(newFilteredArray);
      } else {
        setFilteredArrayData([...safeSportState.arrayData]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safeSportState]);

  function submitFormCallback() {
    const newFilteredArray = safeSportState.arrayData.filter((club) => club.clubOrgUnitId === formState.orgUnitId) || [];

    setFilteredArrayData(newFilteredArray);
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      orgUnitName: '',
      orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC
    };
  };

  return {
    location,
    isArrayLoading: safeSportState.isArrayLoading,
    filteredArrayData,
    formState,
    errorState,
    USAS_ORGANIZATION_ID,
    onEdit,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked
  };
};

export default useSafeSport;