import { useState } from 'react';

import LearnToSwimRegistrationData from './LearnToSwimRegistrationData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_LTS_PROVIDER_REGISTRATION_STATE = {
  ...BASIC_INITIAL_STATE
};

const UseLearnToSwimRegistrationData = () => {
  const [ltsProviderRegistrationState, setLTSProviderRegistrationState] = useState(INITIAL_LTS_PROVIDER_REGISTRATION_STATE);

  const getLTSProviderRegistrations = (isRevisit) => {
    return LearnToSwimRegistrationData.getLTSProviderRegistrationsData(ltsProviderRegistrationState, setLTSProviderRegistrationState, isRevisit);
  };

  const getLTSProviderRegistrationWorkflow = (workflowId) => {
    LearnToSwimRegistrationData.getLTSProviderRegistrationWorkflowData(workflowId, ltsProviderRegistrationState, setLTSProviderRegistrationState);
  };

  const putLTSProviderRegistrationWorkflow = (workflowId, workflowObj) => {
    LearnToSwimRegistrationData.putLTSProviderRegistrationWorkflowData(workflowId, workflowObj, ltsProviderRegistrationState, setLTSProviderRegistrationState);
  };

  return {
    ltsProviderRegistrationState,
    getLTSProviderRegistrations,
    getLTSProviderRegistrationWorkflow,
    putLTSProviderRegistrationWorkflow
  };
};

export default UseLearnToSwimRegistrationData;