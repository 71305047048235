import { useState } from 'react';

import PreCompetitiveRegistrationData from './PreCompetitiveRegistrationData';

const UsePreCompetitiveRegistrationData = () => {
  const [preCompetitiveRegistrationState, setPreCompetitiveRegistrationState] = useState(PreCompetitiveRegistrationData.INITIAL_STATE);

  const getPreCompetitiveRegistration = (isRevisit) => {
    PreCompetitiveRegistrationData.getPreCompetitiveRegistration(preCompetitiveRegistrationState, setPreCompetitiveRegistrationState, isRevisit);
  };

  const getPreCompetitiveRegistrationWorkflow = (workflowId) => {
    PreCompetitiveRegistrationData.getPreCompetitiveRegistrationWorkflow(workflowId, preCompetitiveRegistrationState, setPreCompetitiveRegistrationState);
  };

  const putPreCompetitiveRegistrationWorkflow = (workflowId, workflowObj) => {
    PreCompetitiveRegistrationData.putPreCompetitiveRegistrationWorkflow(workflowId, workflowObj, preCompetitiveRegistrationState, setPreCompetitiveRegistrationState);
  };

  const clearObjData = () => {
    setPreCompetitiveRegistrationState({
      ...preCompetitiveRegistrationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setPreCompetitiveRegistrationState({
      ...preCompetitiveRegistrationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setPreCompetitiveRegistrationState({
      ...preCompetitiveRegistrationState,
      isSaved: false
    });
  };

  return {
    preCompetitiveRegistrationState,
    clearArrayData,
    clearObjData,
    confirmSave,
    getPreCompetitiveRegistration,
    getPreCompetitiveRegistrationWorkflow,
    putPreCompetitiveRegistrationWorkflow
  };
};

export default UsePreCompetitiveRegistrationData;