import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import validate from './MemberClubTransferApprovalValidation';

import { navLinks } from '../../../UseNavLinks';

import usePersonClubTransferData from "../../../state/workflow/personClubTransfer/UsePersonClubTransferData";

import useEnvironmentVariableData from "../../../../common/state/environmentVariable/UseEnvironmentVariableData";

import useForm from "../../../../common/utils/UseForm";
import Constants from "../../../../common/utils/Constants";
import { isValidDate, isValidBirthDate } from "../../../../common/utils/validation";
import { formatDate } from "../../../../common/utils/DateFunctions";

const UseMemberClubTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, PERSON_CLUB_TRANSFER_DAYS } = useEnvironmentVariableData();
  const { personClubTransferState, postPersonClubTransfer, getPersonClubTransferWorkflow
  } = usePersonClubTransferData();
  const { formState, errorState, updateFormState, handleSubmit, setErrors, setFormState, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [attachDateState, setAttachDateState] = useState(Constants.BLANK_DATE_STRING);

  const onRejectModalConfirmClicked = () => {
    handleSubmit();
  };

  const onRejectModalCancelClicked = () => {
    setFormState({
      ...formState,
      isRejected: false,
      stepNotes: ''
    });
  };

  const onBackClicked = () => {
    navigate(navLinks.CLUB_TRANSFER_REQUEST, { state: { isRevisit: location.state?.isRevisit } });
  };

  const onRejectClicked = () => {
    updateFormState('isRejected', true);
  };

  const onApprovedClicked = () => {
    handleSubmit();
  };

  const onLastCompetitionDateChanged = (value) => {
    updateFormState('lastCompetitionDate', value);

    if (value === null) {
      setAttachDateState(formatDate(new Date()));
    } else if (isValidDate(value)) {
      const lastCompetitionDate = new Date(value);
      lastCompetitionDate.setDate(lastCompetitionDate.getDate() + PERSON_CLUB_TRANSFER_DAYS);
      const attachDate = formatDate(lastCompetitionDate);

      if (!isValidBirthDate(attachDate)) {
        setAttachDateState(attachDate);
      } else {
        setAttachDateState(formatDate(new Date()));
      }
    } else {
      setAttachDateState(Constants.BLANK_DATE_STRING);
    }
  };

  useEffect(() => {
    if (location.state?.targetRequest && environmentVariableState.isLoaded === true) {
      const workflowId = location.state.targetRequest?.workflowId;

      const getPersonClubTransferWorkflowPromise = getPersonClubTransferWorkflow(workflowId);

      if (getPersonClubTransferWorkflowPromise !== null) {
        getPersonClubTransferWorkflowPromise.then((newState) => {
          if (newState !== null) {
            setIsDirty(true);

            const lastCompetitionDate = newState.objData?.lastCompetitionDate;
            onLastCompetitionDateChanged(lastCompetitionDate ? formatDate(lastCompetitionDate) : null);
          }
        }).catch((e) => {
          //state error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, environmentVariableState]);

  useEffect(() => {
    if (personClubTransferState.isSaved === true) {
      navigate(navLinks.CLUB_TRANSFER_REQUEST, { state: { isRevisit: location.state?.isRevisit } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personClubTransferState.isSaved]);

  function submitFormCallback(formState) {
    if (personClubTransferState.isObjLoaded === true) {
      if (formState.isRejected === true) {
        postPersonClubTransfer(personClubTransferState.objData.workflowId, personClubTransferState.objData.workflowStepId,
          formState.stepNotes, Constants.WORKFLOW_STATUS_REJECTED, personClubTransferState.objData.lastCompetitionDate);
      } else {
        postPersonClubTransfer(personClubTransferState.objData.workflowId, personClubTransferState.objData.workflowStepId,
          '', Constants.WORKFLOW_STATUS_APPROVED, formState.lastCompetitionDate);
      }
    } else {
      setErrors({ errorOnSubmitAction: 'There was an error when the form was submitted.' });
    }
  };

  function getInitialFormState() {
    return {
      lastCompetitionDate: Constants.BLANK_DATE_STRING,
      isRejected: false,
      stepNotes: ''
    };
  };

  return {
    location,
    Constants,
    isLoading: environmentVariableState.isLoading || personClubTransferState.isObjLoading,
    isSaving: personClubTransferState.isSaving,
    personClubTransferState,
    formState,
    errorState,
    attachDateState,
    onFromValueChanged: updateFormState,
    onLastCompetitionDateChanged,
    onApprovedClicked,
    onBackClicked,
    onRejectClicked,
    onRejectModalConfirmClicked,
    onRejectModalCancelClicked
  };
};

export default UseMemberClubTransfer;