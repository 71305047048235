import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import UsePersonClubTransferData from '../../../state/workflow/personClubTransfer/UsePersonClubTransferData';

import Constants from '../../../../common/utils/Constants';

const UseMemberClubTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { personClubTransferState, getPersonClubTransfer } = UsePersonClubTransferData();

  const onRequestClicked = (e, workflowId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let targetRequest = null;
    for (const request of personClubTransferState.arrayData) {
      if (request.workflowId === workflowId) {
        targetRequest = request;
        break;
      }
    }

    if (targetRequest !== null) {
      navigate(navLinks.CLUB_TRANSFER_REQUEST_APPROVALS, { state: { targetRequest, isRevisit: location.state?.isRevisit } });
    }
  };

  useEffect(() => {
    if (personClubTransferState.isArrayLoaded !== true) {
      if (location.state && location.state?.isRevisit === true) {
        getPersonClubTransfer(true);
      }
      else {
        getPersonClubTransfer(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    location,
    Constants,
    personClubTransferState,
    onRequestClicked
  };
};

export default UseMemberClubTransfer;