import { API_NAMES, initApi } from "../../utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

const postGroupMemberGPAFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/gpa`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getGroupMemberGPAFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/gpa?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_MEMBER_DOCS_GPA_BASE_UPLOAD_URL;

const MEMBER_GROUPS_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MEMBER_GROUPS_FILE_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberGroupsFileData = {
  BASE_UPLOAD_URL,
  MEMBER_GROUPS_FILE_UPLOAD_INITIAL_STATE,
  MEMBER_GROUPS_FILE_DOWNLOAD_INITIAL_STATE,
  postGroupMemberGPAFileData,
  getGroupMemberGPAFileData
};

export default MemberGroupsFileData;