import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import usePreCompetitiveRegistrationData from '../../../state/workflow/preCompetitiveRegistration/UsePreCompetitiveRegistrationData';

import Constants from '../../../../common/utils/Constants';

const usePreCompetitiveRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preCompetitiveRegistrationState, getPreCompetitiveRegistration } = usePreCompetitiveRegistrationData();

  const onRegistrationClicked = (e, preCompReg) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.PRE_COMP_REGISTRATION_APPROVALS, { state: { preCompReg, isRevisit: location.state?.isRevisit } });
  };

  useEffect(() => {
    if (preCompetitiveRegistrationState.isArrayLoaded !== true) {
      if (location.state && location.state?.isRevisit === true) {
        getPreCompetitiveRegistration(true);
      }
      else {
        getPreCompetitiveRegistration(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    location,
    Constants,
    preCompetitiveRegistrationState,
    onRegistrationClicked
  };
};

export default usePreCompetitiveRegistration;