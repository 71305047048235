import { useState } from 'react';

import ClubRecognitionData from './ClubRecognitionData';

const useClubRecognitionData = () => {
  const [clubRecognitionState, setClubRecognitionState] = useState(ClubRecognitionData.INITIAL_STATE);

  const getClubRecognitionRequest = (isRevisit) => {
    ClubRecognitionData.getClubRecognitionRequestData(clubRecognitionState, setClubRecognitionState, isRevisit);
  };

  const getClubRecognitionWorkflow = (workflowId) => {
    ClubRecognitionData.getClubRecognitionWorkflowData(workflowId, clubRecognitionState, setClubRecognitionState);
  };

  const putClubRecognitionWorkflow = (workflowId, workflowObj) => {
    ClubRecognitionData.putClubRecognitionWorkflowData(workflowId, workflowObj, clubRecognitionState, setClubRecognitionState);
  };

  const clearClubRecognitionObjData = () => {
    setClubRecognitionState({
      ...clubRecognitionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearClubRecognitionArrayData = () => {
    setClubRecognitionState({
      ...clubRecognitionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmClubRecognitionSave = () => {
    setClubRecognitionState({
      ...clubRecognitionState,
      isSaved: false
    });
  };

  return {
    clubRecognitionState,
    getClubRecognitionRequest,
    getClubRecognitionWorkflow,
    putClubRecognitionWorkflow,
    clearClubRecognitionArrayData,
    clearClubRecognitionObjData,
    confirmClubRecognitionSave
  };
};

export default useClubRecognitionData;