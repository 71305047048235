import React, { Fragment } from 'react';

import DatePicker from '../../../../../../common/components/datepickers/DatePicker';
import ReadOnly from '../../../../../../common/components/readOnly/ReadOnly';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

const MemberClubTransferApprovalForm = ({ transferObj, isRevisit, attachDateState, formState, errorState, onLastCompetitionDateChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Name:"
          name="name"
          value={`${transferObj.firstName || ''} ${transferObj.lastName || ''}`} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Member Id:"
          name="memberId"
          value={transferObj.memberId || ''} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Current Club:"
          name="transferFormClubName"
          value={transferObj.transferFromClubName || ''} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Transferring To:"
          name="transferToClubName"
          value={transferObj.transferToClubName || ''} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Transfer Initiated Date:"
          name="transferRequestDate"
          value={transferObj.transferRequestDate
            ? formatDate(transferObj.transferRequestDate)
            : ''} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Membership Type:"
          name="orgRoleName"
          value={transferObj.orgRoleName || ''} />
      </div>
      {transferObj.meetName && transferObj.meetEndDate && transferObj.meetStartDate
        && (
          <Fragment>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="Last Meet:"
                name="meetName"
                value={transferObj.meetName || ''} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="Meet Dates:"
                name="meetDates"
                value={`${formatDate(transferObj.meetStartDate)} - ${formatDate(transferObj.meetEndDate)}`} />
            </div>
          </Fragment>
        )
      }
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Is Birth Date Confirmed:"
          name="birthDateConfirmed"
          value={transferObj.birthDateConfirmed === true ? 'Yes' : 'No'} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12col-sm-6 col-md-4">
        {isRevisit === true  ?
        (
          <ReadOnly
            label="Last Open Competition Date:"
            name="lastCompetitionDate"
            value={formState.lastCompetitionDate} />
        ) 
        : formState.lastCompetitionDate === null
          ? (
            <ReadOnly
              label="Last Open Competition Date:"
              name="lastCompetitionDate"
              value='No Competition History' />
          ) : (
            <DatePicker
              label="Last Open Competition Date*"
              name="lastCompetitionDate"
              value={formState.lastCompetitionDate}
              error={errorState.lastCompetitionDate}
              message={errorState.lastCompetitionDate}
              onChange={(value) => { onLastCompetitionDateChanged(value); }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Attach Date:"
          name="attachDate"
          value={attachDateState || ''} />
      </div>
    </div>
  </Fragment >
);

export default MemberClubTransferApprovalForm;