import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import validate from './MeetSanctionApprovalValidation';

import { navLinks } from '../../../UseNavLinks';
import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import UseMeetSanctionData from "../../../state/workflow/meetSanction/UseMeetSanctionData";

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useMeetFileData from '../../../../common/state/meetFile/UseMeetFileData';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';

const useMeetSanctionApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetSanctionState, getMeetSanctionWorkflow, putMeetSanctionWorkflow } = UseMeetSanctionData();
  const { meetState, getMeet, putMeet } = useMeetData();
  const { getMeetFile } = useMeetFileData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData } = useForm(getInitialFormState, submitFormCallback, validate);
  const [meetSancReqObjState, setMeetSancReqObjState] = useState({});
  const [state, setState] = useState({ action: '', tryRedirect: false });

  const onApprovedClicked = () => {
    handleSubmit();
    setState({ ...state, action: 'Approve' });
  };

  const onRejectClicked = () => {
    handleSubmit();
    setState({ ...state, action: 'Reject' });
  }

  const onBackClicked = () => {
    navigate(navLinks.MEET_SANCTION_REQUEST, { state: { isRevisit: location.state?.isRevisit } });
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(meetState.objData.meetAnnouncementUrl);
  };

  useEffect(() => {
    if (location.state?.meetSanc?.workflowId > 0) {
      setMeetSancReqObjState(location.state.meetSanc);
      getMeetSanctionWorkflow(location.state.meetSanc.workflowId);
      getMeet(location.state.meetSanc.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      setFormData({ ...formState, meetSanctionNumber: meetState.objData.meetSanctionNumber || '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded]);

  useEffect(() => {
    if (meetState.isSaved === true && state.action !== '') {
      if (state.action === 'Approve') {
        const workflowObjCopy = JSON.parse(JSON.stringify(meetSanctionState.objData));
        putMeetSanctionWorkflow(meetSancReqObjState.workflowId,
          createNewWorkflowObj(workflowObjCopy, meetSancReqObjState.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
            '', 0));
        setState({ ...state, action: '', tryRedirect: true });
      } else if (state.action === 'Reject') {
        const workflowObjCopy = JSON.parse(JSON.stringify(meetSanctionState.objData));
        putMeetSanctionWorkflow(meetSancReqObjState.workflowId,
          createNewWorkflowObj(workflowObjCopy, meetSancReqObjState.workflowStepId, Constants.WORKFLOW_STATUS_REJECTED,
            '', 0));
        setState({ ...state, action: '', tryRedirect: true });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isSaved, state.action]);

  useEffect(() => {
    if (meetState.isSaved === true && meetSanctionState.isSaved === true && state.tryRedirect === true) {
      navigate(navLinks.MEET_SANCTION_REQUEST, { state: { isRevisit: location.state?.isRevisit } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isSaved, meetSanctionState.isSaved, state.tryRedirect]);

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getMeetFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function submitFormCallback() {
    const meetObjCopy = JSON.parse(JSON.stringify(meetState.objData));
    meetObjCopy.meetSanctionNumber = formState.meetSanctionNumber.trim();

    putMeet(meetObjCopy.meetId, meetObjCopy);
  }

  function getInitialFormState() {
    return {
      meetSanctionNumber: '',
    };
  }

  return {
    location,
    Constants,
    isSaving: meetSanctionState.isSaving || meetState.isSaving,
    isLoading: meetSanctionState.isObjLoading || meetState.isObjLoading,
    hasMeetAnnouncement: meetState.objData?.meetAnnouncementUrl ? true : false,
    meetSanctionState,
    meetSancReqObjState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked,
    onDownloadUploadedFile,
    formState,
    errorState,
    onFormValueChanged
  };
};

export default useMeetSanctionApproval;