import React from 'react';

import useNewClubRegistration from './UseNewClubRegistration';

import { navLinks } from '../../../UseNavLinks';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import ClubRegistrationGrid from '../components/grids/clubRegistrationGrid/ClubRegistrationGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const NewClubRegistrationLSC = () => {
  const {
    location,
    Constants,
    newClubRegistrationState,
    onRegistrationClicked
  } = useNewClubRegistration(navLinks.LSC_NEW_CLUB_REGISTRATION_APPROVALS);

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={`LSC - New Club Registration Queue${location.state?.isRevisit === true? ' - Requires Revisit' : ' - New Requests'}`}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <ClubRegistrationGrid
              data={newClubRegistrationState.arrayData}
              isLoading={newClubRegistrationState.isArrayLoading}
              onRegistrationClicked={onRegistrationClicked} />
          </div>
        </div>
      </WorkFlowNavigation>
      <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={newClubRegistrationState.isArrayLoading} />
    </div>
  );
};

export default NewClubRegistrationLSC;