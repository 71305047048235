import React, { Fragment } from 'react';

import useGroupNationalTeamInviteApproval from './UseGroupNationalTeamInviteApproval';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import GroupNationalTeamApprovalForm from '../components/forms/groupNationalTeamApprovalForm/GroupNationalTeamApprovalForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const GroupNationalTeamInviteApproval = () => {
  const {
    location,
    Constants,
    isLoading,
    isSaving,
    readonlyFormState,
    onBackClicked,
    onApprovedClicked,
    onRejectClicked
  } = useGroupNationalTeamInviteApproval();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"National Team Group Invite" + `${location.state?.isRevisit === true ? ' - Revisit' : ' - New Request'}`}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <GroupNationalTeamApprovalForm formState={readonlyFormState} />
          <div className="col-xs-12 usas-extra-top-margin">
            {location.state?.isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;</Fragment>}
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
            {location.state?.isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onRejectClicked}>Reject</PrimaryButton></Fragment>}
          </div>
        </div>
      </WorkFlowNavigation>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </div>
  );
};

export default GroupNationalTeamInviteApproval;