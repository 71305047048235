import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import useLearnToSwimRegistrationData from '../../../state/workflow/learnToSwimRegistration/UseLearnToSwimRegistrationData';

const useLearnToSwimRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ltsProviderRegistrationState, getLTSProviderRegistrations } = useLearnToSwimRegistrationData();

  const onRegistrationClicked = (e, learnToSwimReg) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.LEARN_TO_SWIM_REGISTRATION_APPROVALS, { state: { learnToSwimReg, isRevisit: location.state?.isRevisit } });
  };

  useEffect(() => {
    if (ltsProviderRegistrationState.isArrayLoaded !== true) {
      if (location.state && location.state?.isRevisit === true) {
        getLTSProviderRegistrations(true);
      }
      else {
        getLTSProviderRegistrations(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    location,
    ltsProviderRegistrationState,
    onRegistrationClicked
  };
};

export default useLearnToSwimRegistration;