import React from 'react';

import useGroupScholasticAllAmericanInvite from './UseGroupScholasticAllAmericanInvite';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import GroupSAAInviteGrid from '../components/grids/groupSAAInviteGrid/GroupSAAInviteGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import Constants from '../../../../common/utils/Constants';

const GroupScholasticAllAmericanInvite = () => {
  const {
    location,
    state,
    groupScholasticAllAmericanInviteState,
    dropdownState,
    onDropdownValueChange,
    onGroupInviteClicked
  } = useGroupScholasticAllAmericanInvite();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={`Scholastic All America Group Invite Queue${location.state?.isRevisit === true? ' - Requires Revisit' : ' - New Requests'}`}>
        <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12 col-md-4 usas-extra-bottom-margin">
              <Dropdown
                label={'Filter By Zone'}
                name={'zoneOrgUnitCode'}
                value={dropdownState.zoneOrgUnitCode}
                options={state.zoneOptions}
                onChange={(value, valueLabel, e) => e && e.target && e.target.value && onDropdownValueChange(value, 'zoneOrgUnitCode', valueLabel, 'zoneOrgUnitName')} />
            </div>
            <div className="col-xs-12 col-md-4 usas-extra-bottom-margin">
              <Dropdown
                label={'Filter By Competition Category'}
                name={'competitionGenderTypeCode'}
                value={dropdownState.competitionGenderTypeCode}
                options={state.competitionCategoryOptions}
                onChange={(value, valueLabel, e) => e && e.target && e.target.value && onDropdownValueChange(value, 'competitionGenderTypeCode', valueLabel, 'competitionGenderTypeName')} />
            </div>
          <div className="col-xs-12">
            <GroupSAAInviteGrid
              data={state.gridData}
              isLoading={groupScholasticAllAmericanInviteState.isArrayLoading}
              onGroupInviteClicked={onGroupInviteClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={groupScholasticAllAmericanInviteState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default GroupScholasticAllAmericanInvite;