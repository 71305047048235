import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './SafeSportCategoryMeasureApprovalsValidation';

import { navLinks } from '../../../UseNavLinks';

import useCategoryMeasureScoreData from '../../../../common/state/categoryMeasureScore/UseCategoryMeasureScoreData';
import useRecFilesData from '../../../../common/state/recFile/UseRecFileData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useSafeSportCategoryMeasureApprovals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryMeasureScoreState, putCategoryMeasureScore } = useCategoryMeasureScoreData();
  const { getRecFile } = useRecFilesData();
  const { environmentVariableState, FILE_UPLOAD_MEASURE_TYPE_ID, KPI_BASIC_MEASURE_TYPE_ID,
    KPI_COACH_ROSTER_MEASURE_TYPE_ID, KPI_COACH_YEARS_MEASURE_TYPE_ID
  } = useEnvironmentVariableData();
  const { formState, errorState, updateFormState, setFormData, handleSubmit
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState({
    categoryMeasure: {}, programLevel: {}, qualifiedOrgUnitCode: '', orgUnitId: '',
    clubName: '', submissionDate: '', tryRedirect: false, isFileUpload: false, isKpi: false
  });

  const createCategoryMeasureScoreObj = () => {
    let obj = {};

    obj = {
      categoryMeasureScoreId: state.categoryMeasure?.categoryMeasureScoreId,
      programLevelInstanceId: state.programLevel?.programLevelInstanceId,
      categoryMeasureId: state.categoryMeasure?.categoryMeasureId,
      submittedValue: state.categoryMeasure?.submittedValue,
      submittedById: null,
      categoryMeasure: undefined,
      score: formState.score,
      notes: formState.notes.trim()
    };

    return obj;
  };

  const onBackClicked = () => {
    navigate(navLinks.SAFE_SPORT_REQUEST, { state: { isRevisit: location.state?.isRevisit } });
  };

  const onCancelClicked = () => {
    navigate(navLinks.SAFE_SPORT_PROGRAM_LEVEL, { state: { programLevel: state.programLevel, isRevisit: location.state?.isRevisit } });
  };

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(state.categoryMeasure?.submittedValue);
  };

  useEffect(() => {
    if (location.state && location.state.categoryMeasure &&
      location.state.programLevel && environmentVariableState.isLoaded === true) {
      const categoryMeasure = location.state.categoryMeasure;
      const programLevel = location.state.programLevel;
      setState({
        ...state,
        categoryMeasure: categoryMeasure,
        programLevel: programLevel,
        qualifiedOrgUnitCode: programLevel.qualifiedOrgUnitCode,
        clubName: programLevel.clubName,
        orgUnitId: programLevel.clubOrgUnitId,
        submissionDate: formatDate(programLevel.submissionDate),
        isFileUpload: categoryMeasure?.measureTypeId === FILE_UPLOAD_MEASURE_TYPE_ID ? true : false,
        isKpi: isKpi(categoryMeasure?.measureTypeId, categoryMeasure?.measureTypeName)
      });
      setFormData({
        ...formState,
        score: categoryMeasure.score >= 0 ? categoryMeasure.score : '',
        notes: categoryMeasure.notes || '',
        minValue: categoryMeasure.minValue,
        maxValue: categoryMeasure.maxValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState])

  useEffect(() => {
    if (state.tryRedirect === true && categoryMeasureScoreState.isSaved === true) {
      navigate(navLinks.SAFE_SPORT_PROGRAM_LEVEL, { state: { programLevel: state.programLevel, isRevisit: location.state?.isRevisit } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, categoryMeasureScoreState])

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getRecFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function submitFormCallback() {
    putCategoryMeasureScore(state.categoryMeasure.categoryMeasureScoreId, createCategoryMeasureScoreObj());
    setState({ ...state, tryRedirect: true });
  }

  function isKpi(measureTypeId, measureTypeName) {
    if (measureTypeId === KPI_BASIC_MEASURE_TYPE_ID
      || measureTypeId === KPI_COACH_ROSTER_MEASURE_TYPE_ID
      || measureTypeId === KPI_COACH_YEARS_MEASURE_TYPE_ID) {
      return true;
    } else if (measureTypeName === Constants.KPI_CLUB_STAFF_MEASURE_TYPE_NAME
      || measureTypeName === Constants.KPI_STAFF_ROLE_MEASURE_TYPE_NAME) {
      return true;
    } else {
      return false
    }
  }

  function getSubmittedValueString(categoryMeasure) {
    if (Array.isArray(categoryMeasure?.multiSelectSubmittedValue) && categoryMeasure.multiSelectSubmittedValue.length > 0) {
      let submittedValueString = '';

      for (let i = 0; i < categoryMeasure.multiSelectSubmittedValue.length; i++) {
        if (i < categoryMeasure.multiSelectSubmittedValue.length - 1) {
          submittedValueString += `${categoryMeasure.multiSelectSubmittedValue[i].submittedValue}, `;
        } else {
          submittedValueString += categoryMeasure.multiSelectSubmittedValue[i].submittedValue;
        }
      }

      return submittedValueString;
    } else {
      return categoryMeasure?.submittedValue || '';
    }
  }

  function getInitialFormState() {
    return {
      score: '',
      notes: '',
      minValue: 0,
      maxValue: 0
    };
  }

  return {
    location,
    Constants,
    state,
    categoryMeasureScoreState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onBackClicked,
    onCancelClicked,
    onDownloadUploadedFile,
    getSubmittedValueString
  };
};

export default useSafeSportCategoryMeasureApprovals;