import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import validate from './ExistingClubRegistrationApprovalValidation';

import { navLinks } from '../../../UseNavLinks';
import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import useExistingClubRegistrationData from "../../../state/workflow/existingClubRegistration/UseExistingClubRegistrationData";

import useForm from "../../../../common/utils/UseForm";
import Constants from '../../../../common/utils/Constants';

const useExistingClubRegistrationApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { existingClubRegistrationState, getExistingClubRegistrationWorkflow, putExistingClubRegistrationWorkflow
  } = useExistingClubRegistrationData();
  const { formState, errorState, updateFormState, handleSubmit, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [clubRegObjState, setClubRegObjState] = useState({});
  const [trySubmitState, setTrySubmitState] = useState(false);

  const onApprovedClicked = () => {
    updateFormState('isRevisit', false);
    setTrySubmitState(true);
  };

  const onRequestClicked = () => {
    updateFormState('isRevisit', true);
    setTrySubmitState(true);
  };

  const onBackClicked = () => {
    navigate(navLinks.EXISTING_CLUB_REGISTRATION, { state: { isRevisit: location.state?.isRevisit } });
  };

  useEffect(() => {
    if (location.state?.clubReg?.workflowId > 0) {
      setClubRegObjState(location.state.clubReg);
      getExistingClubRegistrationWorkflow(location.state.clubReg.workflowId);
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trySubmitState === true) {
      handleSubmit();
      setTrySubmitState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trySubmitState]);

  useEffect(() => {
    if (existingClubRegistrationState.isSaved === true) {
      navigate(navLinks.EXISTING_CLUB_REGISTRATION, { state: { isRevisit: location.state?.isRevisit } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingClubRegistrationState.isSaved]);

  function submitFormCallback() {
    const workflowObjCopy = JSON.parse(JSON.stringify(existingClubRegistrationState.objData));
    if (formState.isRevisit !== true) {
      putExistingClubRegistrationWorkflow(clubRegObjState.workflowId,
        createNewWorkflowObj(workflowObjCopy, clubRegObjState.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
          formState.stepNotes, 0));
    } else {
      putExistingClubRegistrationWorkflow(clubRegObjState.workflowId,
        createNewWorkflowObj(workflowObjCopy, clubRegObjState.workflowStepId, Constants.WORKFLOW_STATUS_SEND_BACK,
          formState.stepNotes, 0));
    }
  };

  function getInitialFormState() {
    return {
      stepNotes: '',
      isRevisit: undefined
    };
  };

  return {
    location,
    Constants,
    isSaving: existingClubRegistrationState.isSaving,
    isLoading: existingClubRegistrationState.isObjLoading,
    existingClubRegistrationState,
    clubRegObjState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onApprovedClicked,
    onRequestClicked,
    onBackClicked
  };
};

export default useExistingClubRegistrationApproval;