/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import useNewClubRegistrationApproval from './UseNewClubRegistrationApproval';

import { navLinks } from '../../../UseNavLinks';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import ClubRegistrationApprovalForm from '../components/forms/clubRegistrationApprovalForm/ClubRegistrationApprovalForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';

const NewClubRegistrationApprovalLSC = () => {
  const {
    location,
    Constants,
    isLoading,
    isSaving,
    newClubRegistrationState,
    clubRegObjState,
    formState,
    errorState,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onBackClicked,
    onFormValueChanged,
    onApprovedClicked,
    onRequestClicked,
    onDownloadApplication
  } = useNewClubRegistrationApproval(navLinks.LSC_NEW_CLUB_REGISTRATION);

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={`LSC - New Club Registration${location.state?.isRevisit === true ? ' - Revisit' : ' - New Request'}`}>
        {newClubRegistrationState.isObjLoaded === true &&
          <form noValidate>
            <ClubRegistrationApprovalForm
              isRevisit={location.state?.isRevisit}
              clubRegObj={clubRegObjState}
              stateObj={newClubRegistrationState.objData}
              formState={formState}
              errorState={errorState}
              onFormValueChanged={onFormValueChanged}
              onDownloadApplication={onDownloadApplication} />
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12">
                {location.state?.isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;</Fragment>}
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                {location.state?.isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onRequestClicked}>Request Additional Info</PrimaryButton>&nbsp;</Fragment>}
              </div>
            </div>
          </form>
        }
      </WorkFlowNavigation>
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </div>
  );
};

export default NewClubRegistrationApprovalLSC;