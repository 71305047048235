/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import useLearnToSwimRegistrationApproval from './UseLearnToSwimRegistrationApproval';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import ViewReport from '../../../../common/components/embeddedReports/ViewReport';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPhoneNumber } from '../../../../common/utils/validation';

const LearnToSwimRegistrationApproval = () => {
  const {
    location,
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    isSaving,
    isLoading,
    learnToSwimRegObj,
    ltsProviderRegistrationState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked,
    onRunLearnToSwimWorkflowReport
  } = useLearnToSwimRegistrationApproval();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={"Learn To Swim Provider Registration Approval" + `${location.state?.isRevisit === true ? ' - Revisit' : ' - New Request'}`}>
        {ltsProviderRegistrationState.isObjLoaded === true &&
          <form noValidate>
            <div className="row usas-extra-bottom-margin">
              <div className="col-xs-12">
                <ActionIntraPageButton type="button" onClick={() => onRunLearnToSwimWorkflowReport()}>Run Application Report</ActionIntraPageButton>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Business Legal Name:"
                  name="entityName"
                  value={learnToSwimRegObj.entityName || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Trade Name/DBA:"
                  name="tradeName"
                  value={learnToSwimRegObj.tradeName || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Tax Id:"
                  name="taxId"
                  value={learnToSwimRegObj.taxId || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Primary Contact:"
                  name="contactFirstName"
                  value={`${learnToSwimRegObj.contactFirstName} ${learnToSwimRegObj.contactLastName}`} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Contact Email:"
                  name="contactEmail"
                  value={learnToSwimRegObj.contactEmail || ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Contact Phone:"
                  name="contactPhone"
                  value={learnToSwimRegObj.contactPhoneNumber ? formatPhoneNumber(learnToSwimRegObj.contactPhoneNumber) : ''} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Submission Date:"
                  name="submissionDate"
                  value={learnToSwimRegObj.submissionDate ? formatDate(learnToSwimRegObj.submissionDate) : ''} />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
                {location.state?.isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onApprovedClicked}>Approve</PrimaryButton>&nbsp;</Fragment>}
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
                {location.state?.isRevisit === false && <Fragment><PrimaryButton type="button" onClick={onRejectClicked}>Reject</PrimaryButton></Fragment>}
              </div>
            </div>
          </form>
        }
      </WorkFlowNavigation>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
    </div>
  );
};

export default LearnToSwimRegistrationApproval;