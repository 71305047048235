import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import useProgramLevelData from '../../../../common/state/programLevel/UseProgramLevelData';

import Constants from '../../../../common/utils/Constants';

const useSafeSportProgramLevel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { programLevelState, getProgramLevelInstance } = useProgramLevelData();
  const [state, setState] = useState({ programLevel: {}, qualifiedOrgUnitCode: '', clubName: '' });

  const onEdit = (e, categoryMeasure) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.SAFE_SPORT_TASK_APPROVALS, { state: { categoryMeasure, programLevel: state.programLevel, isRevisit: location.state?.isRevisit } });
  };

  const onBackClicked = () => {
    navigate(navLinks.SAFE_SPORT_REQUEST, { state: { isRevisit: location.state?.isRevisit } });
  };

  const onCertifyLevelClicked = () => {
    navigate(navLinks.SAFE_SPORT_PROGRAM_LEVEL_APPROVALS, { state: { programLevel: state.programLevel, isRevisit: location.state?.isRevisit } });
  };

  useEffect(() => {
    if (location.state && location.state.programLevel?.programLevelInstanceId) {
      const programLevel = location.state.programLevel;
      setState({
        ...state,
        programLevel: programLevel,
        qualifiedOrgUnitCode: programLevel.qualifiedOrgUnitCode,
        clubName: programLevel.clubName
      });
      getProgramLevelInstance(programLevel.programLevelInstanceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    location,
    Constants,
    programLevelState,
    state,
    onEdit,
    onBackClicked,
    onCertifyLevelClicked
  };
};

export default useSafeSportProgramLevel;