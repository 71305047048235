import { useState } from 'react';

import ExistingClubRegistrationData from './ExistingClubRegistrationData';

const UseExistingClubRegistrationData = () => {
  const [existingClubRegistrationState, setExistingClubRegistrationState] = useState(ExistingClubRegistrationData.INITIAL_STATE);

  const getExistingClubRegistration = (isRevisit) => {
    ExistingClubRegistrationData.getExistingClubRegistration(existingClubRegistrationState, setExistingClubRegistrationState, isRevisit);
  };

  const getExistingClubRegistrationWorkflow = (workflowId) => {
    ExistingClubRegistrationData.getExistingClubRegistrationWorkflow(workflowId, existingClubRegistrationState, setExistingClubRegistrationState);
  };

  const putExistingClubRegistrationWorkflow = (workflowId, workflowObj) => {
    ExistingClubRegistrationData.putExistingClubRegistrationWorkflow(workflowId, workflowObj, existingClubRegistrationState, setExistingClubRegistrationState);
  };

  const clearObjData = () => {
    setExistingClubRegistrationState({
      ...existingClubRegistrationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  };

  const clearArrayData = () => {
    setExistingClubRegistrationState({
      ...existingClubRegistrationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  };

  const confirmSave = () => {
    setExistingClubRegistrationState({
      ...existingClubRegistrationState,
      isSaved: false
    });
  };

  return {
    existingClubRegistrationState,
    clearArrayData,
    clearObjData,
    confirmSave,
    getExistingClubRegistration,
    getExistingClubRegistrationWorkflow,
    putExistingClubRegistrationWorkflow
  };
};

export default UseExistingClubRegistrationData;