import React from 'react';

import useSafeSportProgramLevel from './UseSafeSportProgramLevel';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';

import ProgramCategoryGrid from '../../../../common/components/grids/programCategory/ProgramCategoryGrid';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

const SafeSportProgramLevel = () => {
  const {
    location,
    Constants,
    programLevelState,
    state,
    onEdit,
    onBackClicked,
    onCertifyLevelClicked
  } = useSafeSportProgramLevel();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={`Safe Sport Recognition Program - ${location.state?.isRevisit === true ? 'Revisit' : 'New Request'}`}>
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon /> {`Back to Safe Sport Recognition Program Queue${location.state?.isRevisit === true ? ' - Requires Revisit' : ' - New Requests'}`}
            </button>
          </div>
        </div>
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>LSC Code/Club Code:</b> {state.qualifiedOrgUnitCode}</p>
            <p><b>Club Name:</b> {state.clubName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
          </div>
        </div>
        {location.state?.isRevisit === false &&
          <div className="row">
            <div className="col-xs-12">
              <ActionIntraPageButton type="button" onClick={onCertifyLevelClicked}>Certify/Send Back Level</ActionIntraPageButton>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <ProgramCategoryGrid
              state={programLevelState}
              onEdit={onEdit} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={programLevelState.isObjLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default SafeSportProgramLevel;