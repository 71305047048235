import React from 'react';

import useRow from './useRow';

import LinkButton from '../../../../common/components/buttons/LinkButton';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './ApprovalQueues.module.css';

const TableRow = ({ queue }) => {
  const { onClick } = useRow(queue);

  return (
    <tr>
      <td>{queue.workflowType}</td>
      <td className={style.CountColumnValue}>{queue.pendingCount > 0 ? <LinkButton action={(e) => onClick(e, false)}>{queue.pendingCount}</LinkButton> : queue.pendingCount}</td>
      <td className={style.CountColumnValue}>{queue.supportsRevisit === true ? queue.revisitCount > 0 ?<LinkButton action={(e) => onClick(e, true)}>{queue.revisitCount}</LinkButton> : queue.revisitCount : 'N/A'}</td>
    </tr>
  );
};

const LargeGrid = ({ approvalQueues }) => {
  const noQueuesAvailable = Array.isArray(approvalQueues) === false || approvalQueues?.length === 0;
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(approvalQueues);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Approval Queue'} columnField={'workflowType'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'New Requests'} columnField={'pendingCount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} className={style.CountColumn} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Requires Revisit'} columnField={'revisitCount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} className={style.CountColumn} />
        </tr>
      </thead>
      <tbody>
        {noQueuesAvailable === true && <tr><td colSpan="3">No approval queues are accessible right now.</td></tr>}
        {noQueuesAvailable === false && sortableGridState.sortedGridData.map((queue, i) => (<TableRow key={i} queue={queue} />))}
      </tbody>
    </table>
  );
};

export default LargeGrid;