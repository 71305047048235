import React, { Fragment } from 'react';

import useRow from './useRow';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../common/components/GlobalStyle.module.css';

const NoQueues = () => {
  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        &nbsp;
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className={global.SmallTableRowLabels}>No approval queues are accessible right now.</div>
      </div>
    </div>
  );
};

const GridRow = ({ queue }) => {
  const { onClick } = useRow(queue);

  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>{queue.pendingCount > 0 && <Fragment><ActionIntraPageButton onClick={(e) => onClick(e, false)}>View New Requests</ActionIntraPageButton>&nbsp;</Fragment>}
        {queue.supportsRevisit === true && queue.revisitCount > 0 && <ActionIntraPageButton onClick={(e) => onClick(e, true)}>View Requires Revisit</ActionIntraPageButton>}{queue.pendingCount === 0 && queue.revisitCount === 0 && <span>&nbsp;</span>}</div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Approval Queue:</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{queue.workflowType}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>New Requests:</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{queue.pendingCount}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Requires Revisit:</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{queue.supportsRevisit === true ? queue.revisitCount : 'N/A'}</div>
        </div>
      </div>
    </div>
  );
};

const SmallGrid = ({ approvalQueues }) => {
  const noQueuesAvailable = Array.isArray(approvalQueues) === false || approvalQueues?.length === 0;

  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {noQueuesAvailable === true && <NoQueues />}
      {noQueuesAvailable === false && approvalQueues.map((queue, i) => (<GridRow key={i} queue={queue} />))}
    </div>
  );
};

export default SmallGrid;