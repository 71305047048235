import { useState } from 'react';

import GroupNationalTeamInviteData from './GroupNationalTeamInviteData';

const useGroupNationalTeamInviteData = () => {
  const [groupNationalTeamInviteState, setGroupNationalTeamInviteState] = useState(GroupNationalTeamInviteData.INITIAL_STATE);

  const getGroupNationalTeamInvites = (isRevisit) => {
    GroupNationalTeamInviteData.getGroupNationalTeamInvitesData(groupNationalTeamInviteState, setGroupNationalTeamInviteState, isRevisit);
  };

  const getGroupNationalTeamInviteWorkflow = (workflowId) => {
    GroupNationalTeamInviteData.getGroupNationalTeamInviteWorkflowData(workflowId, groupNationalTeamInviteState, setGroupNationalTeamInviteState);
  };

  const putGroupNationalTeamInviteWorkflow = (workflowId, workflowObj) => {
    GroupNationalTeamInviteData.putGroupNationalTeamInviteWorkflowData(workflowId, workflowObj, groupNationalTeamInviteState, setGroupNationalTeamInviteState);
  };

  return {
    groupNationalTeamInviteState,
    getGroupNationalTeamInvites,
    getGroupNationalTeamInviteWorkflow,
    putGroupNationalTeamInviteWorkflow
  };
};

export default useGroupNationalTeamInviteData;