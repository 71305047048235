import React from 'react';

import useLearnToSwimRegistration from './UseLearnToSwimRegistration';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import LearnToSwimRegistrationGrid from '../components/grids/learnToSwimRegistrationGrid/LearnToSwimRegistrationGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const LearnToSwimRegistration = () => {
  const {
    location,
    ltsProviderRegistrationState,
    onRegistrationClicked
  } = useLearnToSwimRegistration();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={`Learn To Swim Provider Registration Queue${location.state?.isRevisit === true? ' - Requires Revisit' : ' - New Requests'}`}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <LearnToSwimRegistrationGrid
              data={ltsProviderRegistrationState.arrayData}
              isLoading={ltsProviderRegistrationState.isArrayLoading}
              onRegistrationClicked={onRegistrationClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={ltsProviderRegistrationState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default LearnToSwimRegistration;