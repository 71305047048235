import React, { Fragment } from 'react';

import Constants from '../../../../../Constants';

import Textarea from '../../../../../../common/components/inputs/Textarea';
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const ClubRegistrationApprovalForm = ({ isRevisit, clubRegObj, stateObj, formState, errorState, onFormValueChanged, onDownloadApplication }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <div>
            <strong>LSC - Club Name/Club Code: </strong>
            {`${clubRegObj.lscCode || ''} - ${clubRegObj.clubName || ''}/${clubRegObj.clubCode || ''}`}
          </div>
          <div>
            <strong>Submission Date: </strong>
            {formatDate(clubRegObj.submissionDate)}
          </div>
          <div>
            <strong>Club Registration Type: </strong>
            {clubRegObj.offeringName}
          </div>
          <div>
            <strong>Club Registration Completed By: </strong>
            {clubRegObj.completedByFirstName} {clubRegObj.completedByLastName}
          </div>
        </div>
        <div className="col-xs-12 col-sm-6">
          <ActionIntraPageButton className="pull-right" type="button" onClick={() => onDownloadApplication()}>
            {Constants.WORKFLOW_APPLICATION_TEXT}
          </ActionIntraPageButton>
        </div>
        <div className="col-xs-12">
          {isRevisit === true ?
            <div className='usas-extra-bottom-margin'>
              <strong>Notes: </strong>
              {stateObj?.workflowStep?.map(x => {
                let workFlowStepCopySortedFiltered = JSON.parse(JSON.stringify(stateObj?.workflowStep)).sort(
                  (a, b) => b.stepItteration - a.stepItteration).filter(y => y.workflowStepId !== x.workflowStepId);

                if (!workFlowStepCopySortedFiltered?.find(
                    z => z.workflowTypeStepId === x.workflowTypeStepId && z.workflowId === x.workflowId && z.stepStatus === 'Approved')) {
                  return (
                    { ...x }
                  )
                }
              }).sort((a, b) => b.stepItteration - a.stepItteration)?.find(step => step.stepStatus === 'Revisit')?.stepNotes || ''}
            </div>
            :
            <Textarea
              className={global.Textarea}
              label="Notes:"
              name="stepNotes"
              value={formState.stepNotes}
              error={errorState.stepNotes}
              message={errorState.stepNotes}
              makeLabelBold={true}
              onChange={value => onFormValueChanged('stepNotes', value)} />}
        </div>
      </div>
    </Fragment >
  );
}

export default ClubRegistrationApprovalForm;