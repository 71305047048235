import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import UseNewClubRegistrationData from '../../../state/workflow/newClubRegistration/UseNewClubRegistrationData';

import Constants from '../../../../common/utils/Constants';

const useNewClubRegistration = (approvalsPagePath) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { newClubRegistrationState, getNewClubRegistration, getHQNewClubRegistration } = UseNewClubRegistrationData();

  const onRegistrationClicked = (e, clubReg) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(approvalsPagePath, { state: { clubReg, isRevisit: location.state?.isRevisit } });
  };

  useEffect(() => {
    if (newClubRegistrationState.isArrayLoaded !== true && approvalsPagePath) {
      if (approvalsPagePath === navLinks.HQ_NEW_CLUB_REGISTRATION_APPROVALS) {
        if (location.state && location.state?.isRevisit === true) {
          getHQNewClubRegistration(true);
        }
        else {
          getHQNewClubRegistration(false);
        }
      } else {
        if (location.state && location.state?.isRevisit === true) {
          getNewClubRegistration(true);
        }
        else {
          getNewClubRegistration(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClubRegistrationState.isArrayLoaded, approvalsPagePath]);

  return {
    location,
    Constants,
    newClubRegistrationState,
    onRegistrationClicked
  };
};

export default useNewClubRegistration;