import React from 'react';

import usePreCompetitiveRegistration from './UsePreCompetitiveRegistration';

import WorkFlowNavigation from '../components/navigation/WorkFlowNavigation';
import PreCompetitiveRegistrationGrid from '../components/grids/preCompetitiveRegistrationGrid/PreCompetitiveRegistrationGrid';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const PreCompetitiveRegistration = () => {
  const {
    location,
    Constants,
    preCompetitiveRegistrationState,
    onRegistrationClicked
  } = usePreCompetitiveRegistration();

  return (
    <div className="row">
      <WorkFlowNavigation headerTitle={`Pre-Competitive Registration Queue${location.state?.isRevisit === true? ' - Requires Revisit' : ' - New Requests'}`}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PreCompetitiveRegistrationGrid
              data={preCompetitiveRegistrationState.arrayData}
              isLoading={preCompetitiveRegistrationState.isArrayLoading}
              onRegistrationClicked={onRegistrationClicked} />
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={preCompetitiveRegistrationState.isArrayLoading} />
      </WorkFlowNavigation>
    </div>
  );
};

export default PreCompetitiveRegistration;